import './style.css'
//import './index.html'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'dat.gui'
import { Mesh } from 'three'

/**
 * Base
 */
// Debug
const gui = new dat.GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')


// Scene
const scene = new THREE.Scene()

// Axes Helper
const axesHelper = new THREE.AxesHelper()
// scene.add(axesHelper)

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()
const matcapTexture = textureLoader.load('/textures/matcaps/9.png')

/**
 * Material
 */
 const theMaterial = new THREE.MeshMatcapMaterial({ matcap: matcapTexture })

/**
 * Fonts
 */
const fontLoader = new THREE.FontLoader()
// NewYear
fontLoader.load(
    '/fonts/helvetiker_regular.typeface.json',
    (font) =>
    {
        // Geometry, Material and Mesh
        const newyearTextGeometry = new THREE.TextBufferGeometry(
            'New Year',
            {
                font: font,
                size: 0.22,
                height: 0.2,
                curveSegments: 5,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 4
            }    
        )
        // textGeometry.center() oder lang(hier besser):
        newyearTextGeometry.computeBoundingBox()
        newyearTextGeometry.translate(
            - (newyearTextGeometry.boundingBox.max.x - 0.2) * 0.5,
            + (newyearTextGeometry.boundingBox.max.y - 0.2) * 0.99 + 1,
            - (newyearTextGeometry.boundingBox.max.z - 0.3) * 0.5
        )

        // const newyearTextMaterial = new THREE.MeshMatcapMaterial({ matcap: matcapTexture })
        theMaterial.wireframe = false
        const text = new THREE.Mesh(newyearTextGeometry, theMaterial)
        scene.add(text)             
    }
    
)

// SNOW
fontLoader.load(
    '/fonts/helvetiker_regular.typeface.json',
    (font) =>
    {
        // Text
        const snowTextGeometry = new THREE.TextBufferGeometry(
            'SNOW',
            {
                font: font,
                size: 0.5,
                height: 0.2,
                curveSegments: 5,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 4
            }    
        )
        snowTextGeometry.computeBoundingBox()
        snowTextGeometry.translate(
            - (snowTextGeometry.boundingBox.max.x - 0.2) * 0.5,
            + (snowTextGeometry.boundingBox.max.y - 0.2) * 0.99,
            - (snowTextGeometry.boundingBox.max.z - 0.3) * 0.5

        )

        // 
        theMaterial.wireframe = false
        const text = new THREE.Mesh(snowTextGeometry, theMaterial)
        scene.add(text)   

    
        /**
        * Snowball
        */
       // outsideBody
       const snowballGeometry = new THREE.SphereBufferGeometry(0.1, 0.3, 0.7)

       for(let i = 0; i < 100; i++)
       {
           // insideBody
           const snowball = new THREE.Mesh(snowballGeometry, theMaterial)
           
           // position
           snowball.position.x = (Math.random() - 0.5) * 100
           snowball.position.y = (Math.random() - 0.5) * 100
           snowball.position.z = (Math.random() - 0.5) * 100

           // rotation
           snowball.rotation.x = Math.random() * Math.PI
           snowball.rotation.y = Math.random() * Math.PI

           // scale
           const scale = Math.random()
           snowball.scale.set(scale, scale, scale)

            // addToScene
            scene.add(snowball)       
       }

        /**
         * HeartFlakes
         */   
        const x = 0, y = 0

        const heartShape = new THREE.Shape()

        
        heartShape.moveTo( x + 5, y + 5 )
        heartShape.bezierCurveTo( x + 5, y + 5, x + 4, y, x, y )
        heartShape.bezierCurveTo( x - 6, y, x - 6, y + 7,x - 6, y + 7 )
        heartShape.bezierCurveTo( x - 6, y + 11, x - 3, y + 15.4, x + 5, y + 19 )
        heartShape.bezierCurveTo( x + 12, y + 15.4, x + 16, y + 11, x + 16, y + 7 )
        heartShape.bezierCurveTo( x + 16, y + 7, x + 16, y, x + 10, y )
        heartShape.bezierCurveTo( x + 7, y, x + 5, y + 5, x + 5, y + 5 )
                
        // outsideBody
        const heartflakeGeometry = new THREE.ShapeGeometry(heartShape)

        for(let i = 0; i < 1000; i++)
        {
            // insideBody
            const heartflake = new THREE.Mesh(heartflakeGeometry, theMaterial)

            // position
            heartflake.position.x = (Math.random() - 0.5) * 50
            heartflake.position.y = (Math.random() - 0.5) * 50
            heartflake.position.z = (Math.random() - 0.5) * 50


            // rotation
            heartflake.rotation.x = Math.random() * Math.PI
            heartflake.rotation.y = Math.random() * Math.PI

            // scale
            const scale = Math.random() * 0.033
            heartflake.scale.set(scale, scale, scale)

            // addToScene
            scene.add(heartflake)
        }

        /**
         * IcoFlakes
         */
        console.time('IcoFlakes')
        // outsideBody
        const icoflakeGeometry = new THREE.IcosahedronBufferGeometry(0.1,0)
        
        for(let i = 0; i < 500; i++)
        {
            // insideBody
            const icoflake = new THREE.Mesh(icoflakeGeometry, theMaterial)

            // position
            icoflake.position.x = (Math.random() - 0.5) * 10
            icoflake.position.y = (Math.random() - 0.5) * 10
            icoflake.position.z = (Math.random() - 0.5) * 10

            // rotation
            icoflake.rotation.x = Math.random() * Math.PI
            icoflake.rotation.y = Math.random() * Math.PI


            // Scale check weiredness
            /*
            icoflake.scale.x = Math.random()
            icoflake.scale.y = Math.random()
            icoflake.scale.z = Math.random()
            */

            // Scale
            const scale = Math.random() * 0.5
            icoflake.scale.set(scale, scale, scale)

            

            scene.add(icoflake)


        }
        console.timeEnd('IcoFlakes')
        
    }
)


// FEST
fontLoader.load(
    '/fonts/helvetiker_regular.typeface.json',
    (font) =>
    {
        // Geometry, Material and Mesh
        const festTextGeometry = new THREE.TextBufferGeometry(
            'FEST',
            {
                font: font,
                size: 0.5,
                height: 0.2,
                curveSegments: 5,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 4
            }    
        )
        festTextGeometry.computeBoundingBox()
        festTextGeometry.translate(
            - (festTextGeometry.boundingBox.max.x - 0.2) * 0.5,
            - (festTextGeometry.boundingBox.max.y - 0.2) * 0.99,
            - (festTextGeometry.boundingBox.max.z - 0.3) * 0.5

        )

        // const festTextMaterial = new THREE.MeshMatcapMaterial({ matcap: matcapTexture })
        theMaterial.wireframe = false
        const text = new THREE.Mesh(festTextGeometry, theMaterial)
        scene.add(text)             
    }
)

// 2021 / 2022
fontLoader.load(
    '/fonts/helvetiker_regular.typeface.json',
    (font) =>
    {
        // Geometry, Material and Mesh
        const dateTextGeometry = new THREE.TextBufferGeometry(
            '2023 / 2024',
            {
                font: font,
                size: 0.2,
                height: 0.2,
                curveSegments: 5,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 4
            }    
        )
        dateTextGeometry.computeBoundingBox()
        dateTextGeometry.translate(
            - (dateTextGeometry.boundingBox.max.x - 0.2) * 0.5,
            + (dateTextGeometry.boundingBox.max.y - 0.2) * 0.99 - 0.8,
            - (dateTextGeometry.boundingBox.max.z - 0.3) * 0.5

        )

        // const dateTextMaterial = new THREE.MeshMatcapMaterial({ matcap: matcapTexture })
        theMaterial.wireframe = false
        const text = new THREE.Mesh(dateTextGeometry, theMaterial)
        scene.add(text)             
    }
)


/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 1.5
camera.position.y = 0.2
camera.position.z = 2.7



scene.add(camera)
console.log(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()

/*
// Donuts
       
        // a) Check the time to load!!!
        console.time('donuts')

        // outsideBody
        const donutGeometry = new THREE.TorusBufferGeometry(0.3, 0.2, 20, 45)
        // const donutMaterial = new THREE.MeshMatcapMaterial({ matcap: matcapTexture })

        for(let i = 0; i < 100; i++)
        {
            // insideBody
            const donut = new THREE.Mesh(donutGeometry, theMaterial)

            // position
            donut.position.x = (Math.random() - 0.5) * 10
            donut.position.y = (Math.random() - 0.5) * 10
            donut.position.z = (Math.random() - 0.5) * 10

            // change 2 Axes for not being watched by all these bodieseseses
            donut.rotation.x = Math.random() * Math.PI
            donut.rotation.y = Math.random() * Math.PI

            // scale
            const scale = Math.random()
            donut.scale.set(scale, scale, scale)

            // addToScene
            scene.add(donut)
        }
        // b) Check the time to load!!!
        console.timeEnd('donuts')
        


*/